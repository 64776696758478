import React from "react"
import SubNav2 from "../../components/Mypage/SubNav2"
import Authentication from "../../components/Mypage/Authentication"

export default function AuthenticationAccount() {

    return (
        <div>
            <SubNav2></SubNav2>
            <Authentication></Authentication>
        </div>
    )
}